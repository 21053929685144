import React from 'react';
import { useNavigate } from "react-router-dom";
import rmvm from "../img/logos/5.png";
import gurunanak from "../img/logos/1.png";
import dps from "../img/logos/2.png";
import hlx from "../img/logos/3.png";
import lighthouse from "../img/logos/4.png";
import nss from "../img/logos/6.png";
import srms from "../img/logos/7.png";
import sb from "../img/logos/8.png";
import crpf from "../img/logos/9.png";
import shrekusaldas from "../img/logos/10.png";
import pragya from "../img/logos/11.png";
import anc from "../img/logos/12.png";
import maple from "../img/logos/13.png";
import adity from "../img/logos/14.png";
import abs from "../img/logos/15.png";
import abss from "../img/logos/16.png";
import adtty from "../img/logos/17.png";
import saraswati from "../img/logos/18.png";
import ridhi from "../img/logos/19.png";
import stconvent from "../img/logos/20.png";
import light from "../img/logos/21.png";
import MMI from "../img/logos/23.png";
import choturam from "../img/logos/24.png";
import newaray from "../img/logos/22.png";

const sliderContent = [
  { img: hlx },
  { img: lighthouse },
  { img: sb },
  { img: crpf },
  { img: rmvm },
  { img: adity },
  { img: gurunanak },
  { img: dps },
  { img: nss },
  { img: srms },
  { img: anc },
  { img: shrekusaldas },
  // { img: pragya },
  // { img: maple },
  // { img: abs },
  // { img: abss },
  // { img: adtty },
  // { img: saraswati },
  // { img: ridhi },
  // { img: stconvent },
  // { img: light },
  // { img: MMI },
  // { img: choturam },
  // { img: newaray },
];

const Portfolio = () => {
  const navigate = useNavigate();

  const handleSeeMoreClick = () => {
    // Redirect to the next page with sliderContent passed as state
    navigate('/portfolio');
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="py-12 bg-slate-50">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-extrabold text-gray-900 mb-6 text-center">
            Our Portfolio
          </h2>

          <div className="flex justify-center gap-9 flex-wrap">
            {/* Render images */}
            {sliderContent.slice(0, 12).map((item, index) => (
              <div
                key={index}
                className="bg-white h-20 sm:h-32 hover:scale-105 hover:shadow-2xl w-20 sm:w-32 shadow-xl rounded-2xl mb-4"
              >
                <img
                  src={item.img}
                  alt={`Logo ${index}`}
                  className="object-cover w-full h-full rounded-2xl"
                />
              </div>
            ))}
          </div>

          {/* "See More" button */}
          <div className="flex justify-center mt-6">
            <button
              onClick={handleSeeMoreClick}
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg"
            >
              See More
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Portfolio;
