import React from 'react'
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import "./recognition.css";
import image2 from '../../img/smart school transport solutions (1).jpg';
// import image3 from '../../img/smart school transport solutions.jpg';
import image from '../../img/smart school transport solutions1.jpg';
import { Helmet } from 'react-helmet';
// import image4 from '../../img/smart school transport solutions2.jpg';
const images = [
  image2,
  // image3,
  image,
  // image4,
];
const Tasnport = () => {
  return (
    <div>
         <Helmet>
                <meta charSet="utf-8" />
                <title>Tasnport</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
    <div className='font-semibold text-center text-3xl '>
    {/* <h1> Consultancy </h1> */}
    </div>
    <div className="box scroll-container pt-8" id='rec'>
    <Carousel useKeyboardArrows={true} autoPlay={true} interval={2000} infiniteLoop={true} showStatus={false} >
      {images.map((URL, index) => (
        <div className="slide" key={index} >
          <img alt="sample_file" src={URL} />
        </div>
      ))}
    </Carousel>
  </div>
  <div className='font-semibold text-center text-3xl pt-4'>
    <h1> Transport Solution  </h1>
    </div>
     <div className='lg:grid lg:grid-cols-2 grid pt-5'>
      
      <div className='pl-6 justify-center text-justify w-[90%] p-8 shadow-lg shadow-gray-500/50'>
      <b>LiveLake Education</b>  School Transport Services is a leading provider of innovative
transportation solutions designed exclusively for educational institutions. With a
strong commitment to student safety, convenience, and efficiency, 
LiveLake Education revolutionizes the way students travel to and from school.<br/><br/>


Our fleet of state-of-the-art vehicles is equipped with cutting-edge technology,
ensuring a seamless and secure transportation experience. GPS tracking, real-
time monitoring, and advanced safety features are integrated into every vehicle,
giving parents and schools peace of mind.<br/>

We understand the unique needs of schools and parents when it comes to
transportation. That&#39;s why our services are customizable to fit the specific
requirements of each educational institution. From reliable daily routes to special
event transportation, LiveLake Education is the trusted partner for schools and
parents alike.
    
    </div>
    <div className='pl-6 justify-center text-justify w-[90%] p-8 shadow-lg shadow-gray-500/50'>
    Our team of experienced drivers and support staff is dedicated to providing the
highest level of service. Safety, punctuality, and professionalism are our core
values, and we take pride in getting students to school and back home safely,
efficiently, and comfortably.<br/><br/>

At <b>LiveLake Education</b> School Transport Services, we bridge the gap between
education and transportation, making sure that students can focus on learning
while we take care of their journey. Join us on the road to a smarter, safer, and
more convenient way to travel to school.
</div>
      {/* <div className='w-[90%] bg-gray-100 shadow-lg shadow-gray-500/50'>
      <div className="px-6 box scroll-container p-10" id='rec'>
        <img src='/solution.jpg' alt='pic' className=' h-[110%]'/>
       </div>
       </div> */}
       </div>




  </div>
  )
}

export default Tasnport