import React from 'react'

const Resume = () => {
  return (
      <h1 className=' text-center'>
       Coming Soon..
      </h1>
  )
}

export default Resume