import React from 'react'
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import "./recognition.css";
import image2 from '../../img/SCHOOL MAINTANANCE.png';
import { Helmet } from 'react-helmet';
// import image3 from '../../img/monitoring.png';
const images = [
  image2,
  // image3,
];
export const Maintenance = () => {
  return (
    <div>
         <Helmet>
                <meta charSet="utf-8" />
                <title>Maintenance</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
    <div className='font-semibold text-center text-3xl '>
    {/* <h1> Consultancy </h1> */}
    </div>
    <div className="box scroll-container pt-8" id='rec'>
    <Carousel useKeyboardArrows={true} autoPlay={true} interval={2000} infiniteLoop={true} showStatus={false} >
      {images.map((URL, index) => (
        <div className="slide" key={index} >
          <img alt="sample_file" src={URL} />
        </div>
      ))}
    </Carousel>
  </div>
  <div className='font-semibold text-center text-3xl pt-4'>
    <h1>Maintenance Services </h1>
    </div>
     <div className='lg:grid lg:grid-cols-2 grid pt-5'>
      
      
      <div className='pl-6 justify-center text-justify w-[90%] p-8 shadow-lg shadow-gray-500/50 '>
    
       
      <b>LiveLake Education </b>is your trusted partner in ensuring the upkeep and
  functionality of educational institutions. With a commitment to providing top-
tier maintenance solutions, we specialize in keeping schools and colleges safe,
clean, and conducive to learning.<br/>

<li><b>Comprehensive Maintenance:</b> Our team of skilled professionals is well-equipped
to handle a wide range of maintenance needs. From routine cleaning and HVAC
system servicing to electrical, plumbing, and structural repairs, we offer a full
spectrum of services to maintain the integrity of educational facilities.</li>

<li><b>Safety First:</b> Ensuring the safety of students, teachers, and staff is our top
priority. We conduct thorough safety assessments and adhere to all safety
regulations, ensuring that the school or college environment remains secure and
hazard-free.</li>

<li><b>Cost-Efficiency:</b>
 We understand the budget constraints that educational
institutions often face. LiveLake Education offers cost-effective solutions without
compromising on quality. Our tailored maintenance plans are designed to
maximize the longevity of assets while minimizing operational costs.</li>

       </div>


       <div className='pl-6 justify-center text-justify w-[90%] p-8 shadow-lg shadow-gray-500/50 '>
    
    <li><b>Responsive Support:</b> In the event of emergencies or urgent maintenance needs,
our team is available 24/7 to provide rapid response and support. We
understand the critical nature of maintaining uninterrupted operations in
educational settings.</li>

<li> <b>Sustainability:</b> We are committed to sustainable practices. Our maintenance
services include eco-friendly options for energy-efficient systems, waste
reduction, and environmentally conscious cleaning products to minimize the
ecological footprint.</li>

<li><b>Customized Solutions:</b> Every school and college is unique, and our maintenance
plans are customized to meet specific requirements. Whether it&#39;s a small primary
school or a sprawling university campus <b>LiveLake Education</b>  adapts to your needs.</li><br/>

At <b>LiveLake Education </b>, we believe that a well-maintained educational environment
is vital for fostering growth and learning. We take pride in our role as caretakers
of educational spaces, allowing institutions to focus on their core mission of
educating future generations. Join us in nurturing educational environments with
LiveLake Education.
     </div>
    
      {/* <div className='w-[90%] bg-gray-100 shadow-lg shadow-gray-500/50'>
      <div className="px-6 box scroll-container p-10" id='rec'>
        <img src='/solution.jpg' alt='pic' className=' h-[110%]'/>
       </div>
       </div> */}
       </div>




  </div>
  )
}
