import React from 'react'
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import "./recognition.css";
import image2 from '../../img/certification.jpg';
import { Helmet } from 'react-helmet';
// import image3 from '../../img/certification2.jpg';
const images = [
  image2,
  // image3,
];
 const Certification = () => {
  return (
    <div>
         <Helmet>
                <meta charSet="utf-8" />
                <title>Certification</title>
              </Helmet>
    <div className='font-semibold text-center text-3xl '>
    {/* <h1> Consultancy </h1> */}
    </div>
    <div className="box scroll-container pt-8" id='rec'>
    <Carousel useKeyboardArrows={true} autoPlay={true} interval={2000} infiniteLoop={true} showStatus={false} >
      {images.map((URL, index) => (
        <div className="slide" key={index} >
          <img alt="sample_file" src={URL} />
        </div>
      ))}
    </Carousel>
  </div>
  <div className='font-semibold text-center text-3xl pt-4'>
    <h1>Intreactive Online Couces With Certification</h1>
    </div>
     <div className='lg:grid lg:grid-cols-2 grid pt-5'>
      
    
      <div className='pl-6 justify-center text-justify w-[90%]  shadow-lg shadow-gray-500/50 p-8'>

      If you are providing certifications within your Enterprise Resource Planning (ERP) system, it typically means that your ERP software is capable of managing and issuing certificates for various purposes. Here are some common scenarios where ERP systems are used for certification:<br/>

     <li><b>Employee Training and Development:</b> Many companies use ERP systems to manage employee training programs. The ERP can track which employees have completed specific training modules and generate certificates as proof of completion.</li>

<li><b>Compliance and Regulatory Certifications:</b> Certain industries have strict compliance and regulatory requirements. ERP systems can help ensure that all necessary certifications and licenses are up to date for employees and equipment.</li>

<li><b>Product Quality and Compliance:</b> In manufacturing, ERP systems can be used to track the quality and compliance of products. Certificates of compliance can be generated to show that products meet specific standards or requirements.</li>

<li><b>Supplier and Vendor Certifications:</b> Companies often work with suppliers and vendors who need to meet certain quality and compliance standards. ERP systems can track and manage supplier certifications.</li>

      </div>

      <div className='pl-6 justify-center text-justify w-[90%]  shadow-lg shadow-gray-500/50 p-8 '>
    <li><b>Customer Certifications:</b> In some cases, customers may require specific certifications from suppliers or service providers. An ERP system can help manage and provide proof of these certifications.</li>

      <li><b>Online Learning and Training:</b> If your ERP system is used for e-learning or online training, it can issue certificates of completion to learners who successfully finish courses or modules.</li>

      <li><b>Professional Development:</b> For organizations that provide professional development courses or programs, an ERP system can issue certificates to participants.</li>

        The specific features and capabilities of your ERP system for managing certifications may vary depending on the software you are using. It typically involves tracking certification requirements, monitoring progress, and generating certificates automatically when criteria are met.<br/>

Additionally, modern ERP systems may integrate with other platforms, such as learning management systems (LMS), to manage and issue certifications related to training and development.<br/>

If you have specific questions about how your ERP system handles certifications or need guidance on implementing such a system, it would be helpful to provide more details about your ERP software and the context in which you are using it.<br/>





      </div>
      {/* <div className='w-[90%] bg-gray-100 shadow-lg shadow-gray-500/50'>
      <div className="px-6 box scroll-container p-10" id='rec'>
        <img src='/solution.jpg' alt='pic' className=' h-[110%]'/>
       </div>
       </div> */}
       </div>




  </div>
  )
}
export default Certification;