import React from 'react'
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import "./recognition.css";
import image2 from '../../img/Ultra High Frequency.jpg';
import { Helmet } from 'react-helmet';
// import image3 from '../../img/UHF';
const images = [
  image2,
  // image3,
];
const Uhf = () => {
  return (
    <div>
         <Helmet>
                <meta charSet="utf-8" />
                <title>Uhf</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
    <div className='font-semibold text-center text-3xl '>
    {/* <h1> Consultancy </h1> */}
    </div>
    <div className="box scroll-container pt-8" id='rec'>
    <Carousel useKeyboardArrows={true} autoPlay={true} interval={2000} infiniteLoop={true} showStatus={false} >
      {images.map((URL, index) => (
        <div className="slide" key={index} >
          <img alt="sample_file" src={URL} />
        </div>
      ))}
    </Carousel>
  </div>
  <div className='font-semibold text-center text-3xl pt-4'>
    <h1> UHF Based Attendance </h1>
    </div>
     <div className='lg:grid lg:grid-cols-2 grid pt-5'>
      
      <div className=' '>
      <div className='pl-6 justify-center text-justify w-[90%] shadow-lg shadow-gray-500/50 p-8'>
      <b>LiveLake Education </b> is your trusted partner in delivering advanced UHF-based attendance machine services to schools and colleges. With a mission to enhance efficiency, accuracy, and security, we offer cutting-edge solutions that revolutionize how educational institutions manage attendance records.<br></br>

<b>UHF-Based Precision:</b> Our attendance machines utilize Ultra-High Frequency (UHF) technology for unparalleled accuracy and speed in capturing attendance data. This ensures that schools and colleges can maintain precise attendance records without the hassle of manual processes.<br></br>

<b>Seamless Integration:</b> LiveLake Education seamlessly integrates our UHF-based attendance solutions with your existing systems, making implementation effortless. Whether you use traditional attendance registers or digital platforms, we provide a streamlined transition.<br></br>

<b>Real-time Tracking:</b> Stay in control with real-time attendance tracking. Our systems allow schools and colleges to monitor attendance as it happens, enabling quick response to any discrepancies or late arrivals.<br></br>


   
     </div>
      </div>
      {/* <div className='w-[90%] bg-gray-100 shadow-lg shadow-gray-500/50'>
      <div className="px-6 box scroll-container p-10" id='rec'>
        <img src='/solution.jpg' alt='pic' className=' h-[110%]'/>
       </div>
       </div> */}
       <div className='pl-6 justify-center text-justify w-[90%] shadow-lg shadow-gray-500/50 p-8'>
      <li> <b>Robust Reporting:</b> Generate comprehensive attendance reports effortlessly. LiveLake Education  provides customizable reporting tools that offer insights into attendance patterns, helping educational institutions make data-driven decisions.</li>

      <li><b> Security and Compliance:</b> We prioritize data security and compliance with privacy regulations. Our attendance systems are designed to protect sensitive attendance data while ensuring adherence to all relevant laws.</li>

      <li><b>Customized Solutions:</b> Every educational institution is unique. LiveLake Education   tailors our UHF-based attendance services to meet the specific needs and scale of your school or college, whether you're a small primary school or a sprawling university campus.</li>

       At <b>LiveLake Education</b>, we believe that precise attendance management is fundamental to the success of educational institutions. Our UHF-based attendance machine services empower schools and colleges to automate attendance tracking, reduce administrative burden, and enhance overall operational efficiency. Join us in elevating attendance management with LiveLake Education Attendance Solutions.


       </div>
       </div>
  </div>
  )
}

export default Uhf