import React from 'react'
import Intro from '../components/Intro/Intro'
import Recognitions from '../components/Recognitions/Recognitions'
import RemoteProctoring from '../components/RemoteProctoring/RemoteProctoring'
//  import VirtualSchool from '../components/VirtualSchool/VirtualSchool'
// import OnlineCourses from '../components/OnlineCourses/OnlineCourses'
// import Customers from '../components/Customers/Customers'
import Contact from '../components/Contact/Contact'
import { Helmet } from 'react-helmet'
import Testimonial from "../Page/testimonial"

const Home = () => {
  return (
    <>
     <Helmet>
      <title>Livelake Education- 360&deg; Solution Provider</title>     
      </Helmet>
       <Intro/>
       <Recognitions />
       <RemoteProctoring />
      {/* <VirtualSchool /> */}
      {/*<OnlineCourses />
      <Customers />      */}
      {/* <Testimonial/> */}
      <Contact />
    </>
  )
}

export default Home;



 




