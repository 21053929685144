import React, { useState, useEffect, useRef } from "react";
import "./appbar.css";
import { NavLink } from "react-router-dom";
// import { Helmet } from 'react-helmet';
import { IoReorderThreeSharp } from "react-icons/io5";
const MyNavbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuOpenOne, setIsMenuOpenOne] = useState(false);
  const [isMenuOpenTwo, setIsMenuOpenTwo] = useState(false);

  const toggleMenu = () => {
    console.log("not working");
    setIsMenuOpen(!isMenuOpen); 

  
  };

  const toggleMenuOne = () => {
    setIsMenuOpenOne(!isMenuOpenOne);
    // setIsMenuOpenTwo(false);
  };
  const toggleMenuTwo = () => {
    setIsMenuOpenTwo(!isMenuOpenTwo);
    setIsMenuOpenOne(false);
  };

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isDropdownOpenn, setDropdownOpenn] = useState(false);
  const [isDrop, setDrop] = useState(false);

  const toggleDropdown = () => {
    setDrop(isDrop);
    // setDropdownOpenn(false);
  };

  const dropdownRef1 = useRef(null);
  const dropdownRef2 = useRef(null);

  const toggleDrop = () => {
    setDropdownOpen(!isDropdownOpen);
    setDropdownOpenn(false);
  };

  const toggleDropdownn = () => {
    setDropdownOpenn(!isDropdownOpenn);
    setDropdownOpen(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        dropdownRef1.current &&
        !dropdownRef1.current.contains(event.target)
      ) {
        setDropdownOpen(false);
      }
      if (
        dropdownRef2.current &&
        !dropdownRef2.current.contains(event.target)
      ) {
        setDropdownOpenn(false);
      }
    };

    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <nav className="bg-none ">
      <div className="w-full  mx-auto pt-5">
        <div className="flex justify-end " id="toogle-container">
          <div className="hidden md:block">
            <div className=" flex items-baseline space-x-5 ">

              <NavLink
                to="/"
                className="text-black hover:text-red-400 py-1 rounded-md active font-semibold"
                aria-current="page">
                Home
              </NavLink>
                <NavLink
                to="/portfolio"
                className="text-black hover:text-red-400 py-1 rounded-md font-semibold"
                aria-current="page"
                >
                Portfolio 
                </NavLink>

              <NavLink
                to="/digialsoluction"
                className="text-black hover:text-red-400 py-1 rounded-md active font-semibold"
              >
               Services
              </NavLink>

              <NavLink
                to="/networking"
                className="text-black hover:text-red-400 py-1 rounded-md font-semibold"
                aria-current="page"
              >
                Hardware 
              </NavLink>


              <div className="relative inline-block text-left" ref={dropdownRef1}>
              <button
              type="button"
              onClick={toggleDrop}
              className="inline-flex text-black hover:text-red-400 rounded-md font-semibold"
              >
        ERP
      </button>

           {isDropdownOpen && (
        <div className="absolute right-0 z-10 mt-2 w-36 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none font-semibold">
          <div className="py-1" role="none">
            <a
              href="https://erp.schoolsdunia.com/"
              className="text-gray-700 block px-4 py-2 text-sm hover:bg-slate-100 font-semibold"
              role="menuitem"
              tabIndex="-1"
            >
              School
            </a>
            <a
              href="https://erp.schoolsdunia.com/"
              className="text-gray-700 block px-4 py-2 text-sm hover:bg-slate-100 font-semibold"
              role="menuitem"
              tabIndex="-1"
            >
              Colleges
            </a>
            <a
              href="https://erp.schoolsdunia.com/"
              className="text-gray-700 block px-4 py-2 text-sm hover:bg-slate-100 font-semibold"
              role="menuitem"
              tabIndex="-1"
            >
              University
            </a>
          </div>
        </div>
              )}
         </div>
              <NavLink
                to="/cybersecurity"
                className="text-black hover:text-red-400  rounded-md  font-semibold"
              >
              CyberSecurity
              </NavLink>

              <div
                className="relative inline-block text-left"
                ref={dropdownRef2}
              >
                
                {/* <button
                  type="button"
                  onClick={toggleDropdownn}
                  className="inline-flex  text-black hover:text-red-400 rounded-md font- font-semibold"
                >
                  Others
                </button>

                {isDropdownOpenn && (
                  <div className="absolute right-0 z-10 mt-2 w-36 origin-top-right divide-y  font-semibold divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1" role="none">
                      <a
                        href="/robotics"
                        className="text-gray-700 block px-4 py-2 text-sm hover:bg-slate-100"
                        role="menuitem"
                        tabIndex="-1"
                      >
                        Robotics
                      </a>
                      <a
                        href="/resume"
                        className="text-gray-700 block px-4 py-2 text-sm hover:bg-slate-100"
                        role="menuitem"
                        tabIndex="-1"
                      >
                        AI Labs
                      </a>
                      <a
                        href="/language"
                        className="text-gray-700 block px-4 py-2 text-sm hover:bg-slate-100"
                        role="menuitem"
                        tabIndex="-1"
                      >
                        Languages
                      </a>
                    </div>
                  </div>
                )} */}


              </div>
             

              <NavLink
                to="/contact"
                className="text-black hover:text-red-400  py-1 rounded-md font-semibold">
              Contact Us
              </NavLink>

            </div>
          </div>
          <IoReorderThreeSharp onClick={toggleMenu} className="menu-icon  text-3xl" />
        </div>


       
      </div>
       <div className="">
      {isMenuOpen && (
          <div className="">

            <div className="px-1 pt-1 pb-1 sm:px-1 absolute " id="openToogle">
            <NavLink
              to="/"
              className="text-black font-semibold hover:text-red-400 block pl-10 rounded-md "
            >
              Home
            </NavLink>

            <NavLink
              to="digialsoluction"
              className="text-black font-semibold hover:text-red-400 block pl-10  rounded-md text-base "
            >
              Services
            </NavLink>
            
            <NavLink
              to="/networking"
              className="text-black font-semibold hover:text-red-400 block pl-10  rounded-md text-base"
            >
             Hardware
            </NavLink>
            <NavLink
              to="/networking"
              className="text-black font-semibold hover:text-red-400 block pl-10  rounded-md text-base"
            >
             Testimonial
            </NavLink>

      <div className="relative pl-10 inline-block text-left" ref={dropdownRef1}>
           <button
        type="button"
        onClick={toggleDrop}
        className="inline-flex text-black hover:text-red-400 rounded-md font-semibold"
      >
        ERP
      </button>
          <div className=" pl-36">
           {isDropdownOpen && (
           <div className="absolute right-0 z-10  mt-2 w-36 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none font-semibold">
          <div className="py-1" role="none">
            <a
              href="https://erp.schoolsdunia.com/"
              className="text-black block px-4 py-2 text-sm hover:bg-slate-100 font-semibold"
              role="menuitem"
              tabIndex="-1"
            >
              School
            </a>
             <a
              href="https://erp.schoolsdunia.com/"
              className="block px-4 py-2 text-sm hover:bg-slate-100 text-black font-semibold"
              role="menuitem"
              tabIndex="-1"
             >
              Colleges
             </a>
             <a
              href="https://erp.schoolsdunia.com/"
              className=" block px-4 py-2 text-sm hover:bg-slate-100 text-black font-semibold"
              role="menuitem"
              tabIndex="-1"
             >
              University
             </a>
             </div>
              </div>
              )}
             </div>
             </div>
           

            <NavLink
              to="/cybersecurity"
              className="text-black font-semibold hover:text-red-400 block pl-10 rounded-md text-base "
            >
              CyberSecurity
            </NavLink>
             {/* <NavLink
              to="/cybersecurity"
              className="text-black font-semibold hover:text-red-400 block pl-10 rounded-md text-base "
              >
              Testimonial 
             </NavLink> */}
           

             {/* <div
                className="relative inline-block text-left pl-10"
                ref={dropdownRef2}
              >
                <button
                  type="button"
                  onClick={toggleDropdownn}
                  className="inline-fle hover:text-red-400 rounded-md text-black font-semibold"
                >
                  Others
                </button>
                <div className=" pl-44">
                {isDropdownOpenn && (
                  <div className="absolute right-0 z-10 mt-2 w-36 origin-top-right divide-y  font-semibold divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1" role="none">
                      <a
                        href="/robotics"
                        className="text-black font-semibold px-4 py-2 text-sm hover:bg-slate-100"
                        role="menuitem"
                        tabIndex="-1"
                      >
                        Robotics
                      </a>
                      <a
                        href="/resume"
                        className="text-black font-semibold block px-4 py-2 text-sm hover:bg-slate-100"
                        role="menuitem"
                        tabIndex="-1"
                      >
                        AI Labs
                      </a>
                      <a
                        href="/language"
                        className="text-black font-semibold block px-4 py-2 text-sm hover:bg-slate-100"
                        role="menuitem"
                        tabIndex="-1"
                      >
                        Languages
                      </a>
                    </div>
                  </div>
                )}
                </div>
              </div> */}

             {/*         
            <NavLink
              href="/Contact1"
              className="text-black font-semibold hover:text-red-400 block pl-10 rounded-md text-base "
            >
             Contact Us
            </NavLink> */}

          </div>
        </div>
      )}
      </div>

    </nav>
  );
};

export default MyNavbar;
