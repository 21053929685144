import React from 'react'
import RemoteProctoring from '../RemoteProctoring/RemoteProctoring'
import { Helmet } from 'react-helmet'

const digialsoluction = () => {
  return (
    <div>
        <Helmet>
                <title>digitalsolution</title>
        </Helmet>
    <RemoteProctoring/>
    </div>
  )
}
export default digialsoluction




