import React, { useContext, useState } from "react";
import "./Contact.css";
import { themeContext } from "../../Context";
import { AiOutlineMail } from 'react-icons/ai';
import { MdAddIcCall, MdLocationOn } from 'react-icons/md';

const Contact = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  const [done, setDone] = useState(false);
  const [educationType, setEducationType] = useState("school");
  const [user, setUser] = useState({
    user_name: '',
    user_email: '',
    user_mobile: '',
    education_type: '',
    user_place: '',
    location: '',
    user_dec: '',
    exist_erp: '',
    message: ''
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const handleEducationTypeChange = (type) => {
    setEducationType(type);
    setUser((prevUser) => ({ ...prevUser, education_type: type }));
  };

  const sendEmail = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://app.campusbeez.com/api/sendmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      });

      if (response.ok) {
        console.log('API request successful');
        setDone(true);
        setUser({
          user_name: '',
          user_email: '',
          user_mobile: '',
          education_type: '',
          user_place: '',
          location: '',
          user_dec: '',
          exist_erp: '',
          message: ''
        });
      } else {
        setDone(false);
      }
    } catch (error) {
      console.error('API request error:', error);
      setDone(false);
    }
  };

  return (
    <div className="contact-form grid grid-cols-1 md:grid-cols-2">
      <div className="w-left col-lg-4">
        <div className="awesome text-center">
          <span className="text-6xl text-center" style={{ color: darkMode ? 'white' : '' }}>Get in Touch</span><br />
          <div className="address-container">
            <div className="contact-container">
              <div className="mail-part flex">
                <div className="logo"><AiOutlineMail /></div>
                <div><h3><a href="mailto:info@livelake.com" className="font-bold">info@livelakeeducation.com</a></h3></div>
              </div>
              <div className="call-part flex justify-start">
                <div className="logo"><MdAddIcCall /></div>
                <div><h3 className="font-bold ">Call Now (+91) 88-6001-4004</h3></div>
              </div>
              <div className="address-part">
                <div className="logo2"><MdLocationOn /></div>
                <h3 className="text-left font-bold">Ansal API Palam Corporate Plaza TF-427,<br /> 3rd floor A Block,<br />
                  Palam Vihar Gurugram, Haryana 122017</h3>
              </div>
            </div>
            <div className="map">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.416080558785!2d77.01847097468826!3d28.497126790218168!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d19f4900b04d7%3A0x799e41ef0ff8cad!2sEpic%20Corporations!5e0!3m2!1sen!2sin!4v1688118046756!5m2!1sen!2sin" title="map" width="600" height="300" ></iframe>
            </div>
          </div>
          <div className="blur s-blur1" style={{ background: "#ABF1FF94" }}></div>
        </div>
      </div>

      <div className="c-right">
        <form>
        <input type="text" name="user_name" value={user.user_name} onChange={handleInput} className="user" placeholder="Name" required />
        <input type="email" name="user_email" value={user.user_email} onChange={handleInput} className="user" placeholder="Email" required />
        <input type="tel" name="user_mobile" value={user.user_mobile} onChange={handleInput} className="user" placeholder="Mobile No." required />
        <div className="choice">
            <input
              type="radio"
              name="education_type"
              value="school"
              required
              onChange={() => handleEducationTypeChange('school')}
              checked={educationType === 'school'}
            />
            <label htmlFor="school">School</label>

            <input
              type="radio"
              name="education_type"
              value="college"
              required
              onChange={() => handleEducationTypeChange('college')}
              checked={educationType === 'college'}
            />
            <label htmlFor="college">College</label>

            <input
              type="radio"
              name="education_type"
              value="university"
              required
              onChange={() => handleEducationTypeChange('university')}
              checked={educationType === 'university'}
            />
            <label htmlFor="university">University</label>
          </div>

          <input type="text" name="user_place" value={user.user_place} onChange={handleInput} className="user" placeholder="Name of School/College/University" />
          <input type="text" name="location" value={user.location} onChange={handleInput} className="user" placeholder="Location" />

          <div>
            <label>Are you a decision maker : </label>
            <input type="radio" name="user_dec" value="Yes" onChange={handleInput} />
            <label htmlFor="yes">Yes</label>
            <input type="radio" name="user_dec" value="No" onChange={handleInput} />
            <label htmlFor="no">No</label>
          </div>

          <div>
            <label>Using any existing ERP : </label>
            <input type="radio" name="exist_erp" value="Yes" onChange={handleInput} />
            <label htmlFor="yes">Yes</label>
            <input type="radio" name="exist_erp" value="No" onChange={handleInput} />
            <label htmlFor="no">No</label>
          </div>

          <textarea name="message" value={user.message} onChange={handleInput} className="user1" placeholder="Message" />
          <input type="submit" className="button" value="Submit" onClick={sendEmail} />

          <span>{done && "Thanks for contacting us"}</span>
          <div className="blur c-blur1" style={{ background: "var(--purple)" }}></div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
