// import React from 'react'
// import SimpleImageSlider from "react-simple-image-slider";
// import image2 from '../../img/2.png';
// import image3 from '../../img/3.png';
// import "./recognition.css";

// const Recognitions = () => {
//     const images = [
//         { url: image2 },
//         { url: image3 }
//       ];

//   return (
//     <div className="flex justify-center" id='slider-container' >
//       <SimpleImageSlider
//         width="90%"
//         height={504}
//         images={images}
//         showBullets={true}
//         showNavs={true}
//       />
//     </div>
//   );
// }


    


// export default Recognitions;

import React from 'react'
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./recognition.css";
import image2 from '../../img/2.png';
import image3 from '../../img/3.png';
const images = [
  image2,
  image3,
];
function Recognitions() {
  return (
    <div className="box scroll-container" id='rec'>
      <Carousel useKeyboardArrows={true} autoPlay={true} interval={2000} infiniteLoop={true} showStatus={false} >
        {images.map((URL, index) => (
          <div className="slide" key={index} >
            <img alt="sample_file" src={URL} />
          </div>
        ))}
      </Carousel>
    </div>
  );
}
export default Recognitions;