import React from 'react'
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import "./recognition.css";
// import image2 from '../../img/s';
import image3 from '../../img/CYBER SECURITY.png';
import { Helmet } from 'react-helmet';
const images = [
  // image2,
  image3,
];

const Cybersecurity = () => {
  return (
    <div>
         <Helmet>
                <meta charSet="utf-8" />
                <title> Cybersecurity</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
    <div className='font-semibold text-center text-3xl '>
    {/* <h1> Consultancy </h1> */}
    </div>
    <div className="box scroll-container" id='rec'>
    <Carousel useKeyboardArrows={true} autoPlay={true} interval={2000} infiniteLoop={true} showStatus={false} >
      {images.map((URL, index) => (
        <div className="slide" key={index} >
          <img alt="sample_file" src={URL} />
        </div>
      ))}
    </Carousel>
  </div>
  <div className='font-semibold text-center text-3xl pt-4'>
    <h1> Cyber Security  </h1>
    </div>
     <div className='lg:grid lg:grid-cols-2 grid pt-5'>
      
   
      <div className='pl-6 justify-center text-justify w-[90%]  shadow-lg shadow-gray-500/50 p-8'>
      
  Our Company is a leading cybersecurity provider dedicated to securing digital environments and protecting organizations and individuals from the ever-evolving landscape of cyber threats. With a team of seasoned experts in cybersecurity, we employ cutting-edge technologies, robust methodologies, and a proactive approach to deliver comprehensive security solutions.<br/>

<b>Key Services and Expertise:</b>

   <li><b>Cybersecurity Consultation:</b> We offer in-depth cybersecurity consultation services to assess an organization's current security posture, identify vulnerabilities, and develop customized strategies to mitigate risks.</li>

    <li><b>Threat Detection and Response:</b> We provide advanced threat detection and response services, leveraging state-of-the-art tools and methodologies to monitor networks, detect threats in real-time, and swiftly respond to security incidents.</li>

     <li><b>Vulnerability Assessment and Penetration Testing:</b> Our team conducts thorough vulnerability assessments and penetration testing to identify weaknesses in systems, applications, and networks, allowing organizations to proactively address potential security flaws.</li>

      <li><b>Security Awareness Training:</b> We offer comprehensive security awareness training programs for employees, helping organizations foster a culture of cybersecurity awareness and empowering staff to recognize and mitigate potential threats.</li>

          <li><b>Data Protection and Encryption:</b> Our Company  assist organizations in implementing data protection measures, including encryption, data loss prevention, and secure data storage, to safeguard sensitive information.</li>

          <li><b>Firewall and Intrusion Detection Systems (IDS/IPS):</b> We deploy and manage firewalls and intrusion detection/prevention systems to safeguard networks and prevent unauthorized access.</li>
          <li><b>Incident Response and Recovery:</b> In the event of a security breach, our experts provide incident response services to contain and mitigate the impact of the breach and assist in the recovery process.</li>

      
      </div>
      <div className='pl-6 justify-center text-justify w-[90%]  shadow-lg shadow-gray-500/50 p-8'>
      
     
<li><b>Compliance and Regulatory Support:</b> We help organizations meet industry-specific regulatory compliance requirements, ensuring they adhere to data protection and cybersecurity standards.</li>

          <li><b>Security Audits and Assessments:</b> We conduct regular security audits and assessments to evaluate the effectiveness of security controls and make recommendations for improvements.</li><br/>

        <b>Benefits:</b>

      <li> <b>Protection from Cyber Threats:</b> We help organizations defend against a wide range of cyber threats, including malware, ransomware, phishing, and insider threats.</li>
  
       <li><b>Data Privacy and Compliance:</b> Ensure compliance with data protection regulations and industry standards, reducing the risk of legal and financial repercussions.</li>
  
         <li><b>Proactive Security:</b> We take a proactive approach to cybersecurity, identifying vulnerabilities and weaknesses before they can be exploited.</li>
  
       <li><b>Business Continuity:</b> Minimize disruptions to operations and ensure business continuity in the face of cyber incidents.</li>
  
        <li><b>Cybersecurity Awareness:</b> Improve cybersecurity awareness among employees, reducing the likelihood of human error leading to breaches.</li>
  
        <li><b>Peace of Mind:</b> Organizations and individuals can operate with confidence, knowing that their digital assets are secure.</li><br/>
          we understand that cybersecurity is a critical aspect of modern business and personal life. Our commitment to staying ahead of emerging threats and delivering tailored security solutions is unwavering. We are dedicated to providing comprehensive cybersecurity services that empower organizations and individuals to thrive in the digital age. Contact us today to fortify your digital defenses and secure your digital future.


          </div>
      
      {/* <div className='w-[90%] bg-gray-100 shadow-lg shadow-gray-500/50'>
      <div className="px-6 box scroll-container p-10" id='rec'>
        <img src='/solution.jpg' alt='pic' className=' h-[110%]'/>
       </div>
       </div> */}
       </div>




  </div>
  )
}   

export default Cybersecurity