import React from 'react'
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import "./recognition.css";
import image2 from '../../img/smart school solutions.png';
import image3 from '../../img/smart school solutions1.jpg';
import { Helmet } from 'react-helmet';
const images = [
  image2,
  image3,
];
export const Smartschool = () => {
  return (
    <div>
         <Helmet>
                <meta charSet="utf-8" />
                <title>Smart school</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
    <div className='font-semibold text-center text-3xl '>
    {/* <h1> Consultancy </h1> */}
    </div>
    <div className="box scroll-container pt-8" id='rec'>
    <Carousel useKeyboardArrows={true} autoPlay={true} interval={2000} infiniteLoop={true} showStatus={false} >
      {images.map((URL, index) => (
        <div className="slide" key={index} >
          <img alt="sample_file" src={URL} />
        </div>
      ))}
    </Carousel>
  </div>
  <div className='font-semibold text-center text-3xl pt-4'>
    <h1> Smart Schools  </h1>
    </div>
     <div className='lg:grid lg:grid-cols-2 grid pt-5'>
      
      <div className=' '>
      <div className='pl-6 justify-center text-justify w-[90%]  shadow-lg shadow-gray-500/50 p-8'>
      It seems like you want to discuss or learn more about smart schools. Smart schools are educational institutions that leverage technology and innovative approaches to enhance the learning experience for students and improve overall school management. Here are some key aspects of smart schools: <br/>

<li><b>Technology Integration:</b> Smart schools use technology extensively in the classroom. This includes interactive whiteboards, tablets or laptops for students, online learning platforms, and educational software.<br/></li>

<li><b>Digital Learning Resources:</b> Smart schools offer a wide range of digital learning resources, such as e-books, educational apps, and online courses. These resources can make learning more engaging and accessible.<br/></li>

<li><b>Personalized Learning:</b> Technology enables smart schools to implement personalized learning plans for students. This tailors the curriculum to individual students' needs and learning styles.<br/></li>

<li><b>Data Analytics:</b> Smart schools use data analytics to track student performance and identify areas where improvement is needed. This data-driven approach can help educators make informed decisions.<br/></li>

<li><b>Communication and Collaboration:</b> Smart schools use technology to facilitate communication between teachers, students, and parents. This includes email communication, online portals for parents, and collaborative tools for students.<br/></li>

<li><b>Security:</b> With the increased use of technology, smart schools prioritize cybersecurity to protect sensitive student data and ensure a safe online environment.<br/></li>


      </div>
      </div>
      {/* <div className='w-[90%]  shadow-lg shadow-gray-500/50'>
      <div className="px-6 box scroll-container p-10" id='rec'>
        <img src='/consultancy1.jpg' alt='pic' className=''/>
       </div>
       </div> */}
         <div className='pl-6 justify-center text-justify w-[90%]  shadow-lg shadow-gray-500/50 p-8'>
       <li> <b>Infrastructure:</b> Smart schools invest in a robust IT infrastructure, including high-speed internet, Wi-Fi networks, and devices for students and teachers.<br/></li>

 <li><b>Professional Development:</b> Teachers in smart schools receive training on how to effectively use technology in the classroom. Continuous professional development is crucial for keeping educators up to date with the latest tools and methodologies.<br/></li>

<li><b>Energy Efficiency:</b> Smart schools may incorporate energy-efficient technologies, such as LED lighting and smart HVAC systems, to reduce energy consumption and lower operational costs.<br/></li>

<li><b>Sustainability:</b> Some smart schools emphasize sustainability by incorporating eco-friendly practices and teaching students about environmental conservation.<br/></li>

<li><b>Parental Involvement:</b> Smart schools often have platforms that allow parents to monitor their child's progress, access assignments, and communicate with teachers more easily.<br/></li>

<li><b>Assessment and Feedback:</b> Technology aids in the assessment process, allowing for automated grading and providing instant feedback to students.<br/></li>

Smart schools aim to create a more engaging and effective learning environment while preparing students for the digital age. They also seek to streamline administrative processes for school management. The specific features and technologies used in smart schools can vary widely, but the goal is to harness technology for the benefit of education.<br/>






      </div>
       </div>
  </div>
  )
}
