import React from 'react'
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import "./recognition.css";
import image2 from '../../img/furniture1.jpg';
import image3 from '../../img/SCHOOL FURNITURE.png';
import { Helmet } from 'react-helmet';
const images = [
  image2,
  image3,
];
const Furniture = () => {
  return (
    <div>
         <Helmet>
                <meta charSet="utf-8" />
                <title>Furniture</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
    <div className='font-semibold text-center text-3xl '>
    {/* <h1> Consultancy </h1> */}
    </div>
    <div className="box scroll-container pt-8" id='rec'>
    <Carousel useKeyboardArrows={true} autoPlay={true} interval={2000} infiniteLoop={true} showStatus={false} >
      {images.map((URL, index) => (
        <div className="slide" key={index} >
          <img alt="sample_file" src={URL} />
        </div>
      ))}
    </Carousel>
  </div>
  <div className='font-semibold text-center text-3xl pt-4'>
    <h1> Furniture </h1>
    </div>
     <div className='lg:grid lg:grid-cols-2 grid pt-5'>
      
      
      <div className='pl-6 justify-center text-justify w-[90%] shadow-lg shadow-gray-500/50 p-8'>
      Our is a leading provider of educational furniture solutions, dedicated to enhancing the learning experience in schools and colleges. With a deep understanding of the specific needs of educational institutions, we offer a comprehensive range of furniture products and services designed to create comfortable, functional, and aesthetically pleasing learning environments.<br/>

  <b>Key Services and Products:</b>

   <li><b>Classroom Furniture:</b> We provides a diverse selection of classroom furniture, including desks, chairs, and tables. Our products are designed to accommodate various age groups, teaching styles, and classroom layouts, fostering an engaging and collaborative learning atmosphere.</li>

     <li><b>Library Furniture:</b> We offer library furniture solutions that encourage reading, research, and creativity. Our library furniture includes shelves, reading tables, and comfortable seating options to create inviting library spaces.</li>

       <li><b>Laboratory Furniture:</b> For colleges and universities, we provide laboratory furniture and workstations that meet safety and functionality standards. Our lab furniture ensures students have the necessary equipment and workspace for hands-on learning.</li>

        <li><b>Cafeteria and Common Area Furniture:</b> Create comfortable and functional common areas with our cafeteria tables, seating, and lounge furniture. These spaces foster social interaction and relaxation for students and faculty.</li>

        <li><b>Auditorium and Lecture Hall Seating:</b> We offer auditorium and lecture hall seating solutions designed for comfort and space optimization. Our seating options can be customized to fit various auditorium sizes and layouts.</li>
        <li><b>Outdoor Furniture:</b> We provide durable and weather-resistant outdoor furniture for school and college campuses. This includes benches, picnic tables, and seating arrangements for outdoor learning and recreation areas.</li>

       
     </div>
     <div className='pl-6 justify-center text-justify w-[90%] shadow-lg shadow-gray-500/50 p-8 '>
     <li><b>Custom Furniture Design:</b> LiveLake Education offers custom furniture design services, allowing educational institutions to create unique, tailored solutions that align with their branding and specific needs.</li>

        <li><b>Installation and Assembly:</b> Our experienced team handles the installation and assembly of all furniture products, ensuring a hassle-free process for educational institutions.</li>
     <b>Benefits:</b>

     <li><b>Enhanced Learning Environments:</b> Our furniture solutions create comfortable and functional spaces that enhance the learning experience for students and educators.</li>

      
      <li><b>Durability and Safety:</b> We prioritize the use of high-quality materials and adhere to safety standards to ensure the longevity and safety of our furniture products.</li>

          <li><b>Aesthetic Appeal:</b> Our furniture options are designed to be aesthetically pleasing, contributing to a positive and inspiring educational atmosphere.</li>

          <li><b>Customization:</b> We offer flexibility in design and customization, allowing institutions to align furniture with their unique requirements and preferences.</li>

             <li><b>Ergonomics:</b> Our furniture is designed with ergonomics in mind, promoting comfort and well-being for users.</li>

          <li><b>Space Optimization:</b> We help institutions make the most of their available space, whether it's a classroom, library, or common area.</li><br/>

          we understand that the right furniture can have a significant impact on the learning environment. Our commitment to providing high-quality, durable, and customized furniture solutions is aimed at creating spaces where students can thrive. Contact us today to transform your educational spaces with our comprehensive furniture offerings.

     </div>
      
      {/* <div className='w-[90%] bg-gray-100 shadow-lg shadow-gray-500/50'>
      <div className="px-6 box scroll-container p-10" id='rec'>
        <img src='/solution.jpg' alt='pic' className=' h-[110%]'/>
       </div>
       </div> */}
       </div>




  </div>
  )
}

export default Furniture