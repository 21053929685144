import React from 'react'
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import "./recognition.css";
import image2 from '../../img/uniform1.jpg';
import image3 from '../../img/uniform.jpg';
import { Helmet } from 'react-helmet';
const images = [
  image2,
  image3,
];
const Uniform = () => {
  return (
    <div>
         <Helmet>
                <meta charSet="utf-8" />
                <title>Uniform & Accessories</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
    <div className='font-semibold text-center text-3xl '>
    {/* <h1> Consultancy </h1> */}
    </div>
    <div className="box scroll-container pt-8" id='rec'>
    <Carousel useKeyboardArrows={true} autoPlay={true} interval={2000} infiniteLoop={true} showStatus={false} >
      {images.map((URL, index) => (
        <div className="slide" key={index} >
          <img alt="sample_file" src={URL} />
        </div>
      ))}
    </Carousel>
  </div>
  <div className='font-semibold text-center text-3xl pt-4'>
    <h1>Uniform And Accessories  </h1>
    </div>
     <div className='lg:grid lg:grid-cols-2 grid pt-5'>
      
      <div className=' '>
      <div className='pl-6 justify-center text-justify w-[90%] shadow-lg shadow-gray-500/50 p-8 '>
    <b>LiveLake Education</b>  is your premier partner in providing comprehensive uniform
and gadget solutions tailored to the unique needs of educational institutions.
With a commitment to enhancing school and college identity, we offer a wide
range of services and products that ensure students and staff are well-equipped
and proudly represent their institutions.<br/>

<li><b>Uniforms:</b> We specialize in crafting uniforms that reflect your school or college&#39;s
values and brand. From stylish and comfortable attire for students to
professional and functional uniforms for educators, our team ensures every
piece meets the highest quality standards.</li>

<li><b>Gadgets:</b> In the digital age, access to the right technology is essential for effective
education. LiveLake Education offers a range of gadgets and tech accessories
designed for educational use, including laptops, tablets, and interactive
whiteboards. We source and provide the latest technology to facilitate modern
learning environments.</li>
<li> <b>Customization:</b> We understand that each institution is unique, so we offer
customization options for uniforms and gadgets. Your school or college logo,
colors, and branding can be seamlessly integrated into our products, creating a
sense of unity and pride among students and staff.</li>
     
      </div>
      </div>
      <div className='pl-6 justify-center text-justify w-[90%] shadow-lg shadow-gray-500/50 p-8 '>


<li><b>Sustainability:</b> LiveLake Education  is committed to environmental responsibility.
We offer eco-friendly uniform options made from sustainable materials and take
steps to minimize our ecological footprint in the production and distribution of
gadgets.</li>

<li><b>Service Excellence:</b> Our dedicated team is passionate about providing top-notch
customer service. We work closely with educational institutions to understand
their specific requirements and deliver tailored solutions that exceed
expectations.</li>

<li>At <b>LiveLake Education</b>, we believe that a unified and well-equipped educational
community fosters a sense of belonging and pride. We are here to support
schools and colleges in creating a cohesive identity and equipping students and
staff with the tools they need to succeed in today&#39;s dynamic educational
landscape. Join us in elevating your school or college&#39;s identity with [Company
Name].</li>

     
      </div>
      {/* <div className='w-[90%] bg-gray-100 shadow-lg shadow-gray-500/50'>
      <div className="px-6 box scroll-container p-10" id='rec'>
        <img src='/solution.jpg' alt='pic' className=' h-[110%]'/>
       </div>
       </div> */}
       </div>
  </div>
  )
}

export default Uniform