import React from 'react';
import { FaFacebook, FaLinkedin, FaInstagram } from 'react-icons/fa';
import { FaHome, FaTasks, FaTools, FaComment, FaBriefcase, FaShieldAlt, FaPhoneAlt } from "react-icons/fa";
import { MdOutlineDisplaySettings } from "react-icons/md";

const Footer = () => {
  return (
    <footer className="bg-gray-800 mt-5 h-auto text-white py-8">
      <div className="container mx-auto flex flex-wrap justify-between">
        <div className="w-full md:w-1/2 lg:w-1/6 grid justify-center mb-4 lg:mb-0">
          <h3 className="text-lg font-semibold mb-2">Navigation</h3>
          <ul>
            <li><a href="/" className="flex items-center text-sm py-1"><span className="mr-2"><FaHome /></span>Home</a></li>
            <li><a href="/digialsoluction" className="flex items-center text-sm py-1"><span className="mr-2"><FaTasks /></span>Services</a></li>
            <li><a href="/networking" className="flex items-center text-sm py-1"><span className="mr-2"><MdOutlineDisplaySettings /></span>Hardware</a></li>
            <li><a href="/Portfolio" className="flex items-center text-sm py-1"><span className="mr-2"><FaComment /></span>Testimonial</a></li>
            <li><a href="#" className="flex items-center text-sm py-1"><span className="mr-2"><FaBriefcase /></span>ERP</a></li>
            <li><a href="/cybersecurity" className="flex items-center text-sm py-1"><span className="mr-2"><FaShieldAlt /></span>CyberSecurity</a></li>
            <li><a href="/contact" className="flex items-center text-sm py-1"><span className="mr-2"><FaPhoneAlt /></span>Contact Us</a></li>
          </ul>
        </div>
        <div className="w-full md:w-1/2 lg:w-1/4 mb-4 lg:mb-0">
          <h3 className="text-lg font-semibold mb-4">LiveLake Education Pvt. Ltd.</h3>
          <p className="text-sm">TF-427, A-Block, Ansal API Palam Corporate Plaza</p>
          <p className="text-sm">Palam Vihar Gurugram, Haryana 122017 (India)</p>
          <p className="text-sm">Phone: +91 8860014004</p>
          <p className="text-sm">Email: info@livelakeeducation.com</p>
        </div>
        <div className="w-full md:w-1/2 lg:w-1/4 mb-6 lg:mb-0">
          <h3 className="text-lg font-semibold mb-4">Connect with us</h3>
          <ul className="flex">
            <li className="mr-4"><a href="https://www.facebook.com/live.lake.india/"><FaFacebook className="text-2xl" /></a></li>
            {/* <li className="mr-4"><a href="#"><FaLinkedin className="text-2xl" /></a></li> */}
            <li className="mr-4"><a href="https://www.instagram.com/livelakeeducation/"><FaInstagram className="text-2xl" /></a></li>
          </ul>
          {/* <form className="flex py-7">
            <input type="email" placeholder="Your email" className="py-2 px-2 bg-gray-700 text-white border border-gray-700 rounded-l focus:outline-none" />
            <button type="submit" className="bg-blue-500 hover:bg-blue-600 rounded-r px-1">Subscribe</button>
          </form> */}
        </div>
      </div>
      <div className="border-t border-gray-700 mt-2 pt-3 text-center">
        <p className="text-xs">&copy; {new Date().getFullYear()} LiveLake Education Pvt Ltd. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
