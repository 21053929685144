import React, { useContext } from "react";
import "./Intro.css";
import Vector2 from "../../img/Add a little bit of body text (700 x 600 px) (1).png";
import { FaBuilding } from 'react-icons/fa'
import { GiTeacher } from 'react-icons/gi'
import { FaBookReader } from 'react-icons/fa'
import { SiGotomeeting } from 'react-icons/si'
import { MdAssignment } from 'react-icons/md'
import { SiSpeedtest } from 'react-icons/si'
import { themeContext } from "../../Context";
import { motion } from "framer-motion";
import HeadingText from "../HeadingText/HeadingText";
import Portfolionewpage from "../../Page/portfolionewpage";

const Intro = () => {
  const transition = { duration: 2, type: "spring" };
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  return (
    <>
      <div className="Intro" id="Intro">
        <div className="i-left">
          <div className="i-name">
            <span style={{ color: darkMode ? "white" : "" }} id="fade"><HeadingText /></span>
            <span>Guiding Excellence, Inspiring Results</span>
          </div>
        </div>

        <div className="i-right">
          <img src={Vector2} alt="" className="responsive-image md:block hidden" />
          <motion.div
            initial={{ left: "9rem", top: "18rem" }}
            whileInView={{ left: "0rem" }}
            transition={transition}
            className="floating-div"
          ></motion.div>
          <div className="blur" style={{ background: "rgb(238 210 255)" }}></div>
          <div
            className="blur"
            style={{
              background: "#C1F5FF",
              top: "17rem",
              width: "21rem",
              height: "11rem",
              left: "-9rem",
            }}
          ></div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-6">
          <div className='shadow-lg p-5 text-center rounded-lg transition ease-in-out delay-150 bg-Slate-50 hover:-translate-y-1 hover:scale-110 hover:bg-Slate-100 duration-300'>
            <FaBuilding className="mx-auto" size={40} />
            <h3 className='text-xl'>200+</h3>
            <p>Institutions</p>
          </div>
          <div className='shadow-lg p-5 text-center rounded-lg transition ease-in-out delay-150 bg-Slate-50 hover:-translate-y-1 hover:scale-110 hover:bg-Slate-100 duration-300'>
            <GiTeacher className="mx-auto" size={40} />
            <h3 className='text-xl'>1000+</h3>
            <p>Teachers</p>
          </div>
          <div className='shadow-lg p-5 text-center rounded-lg transition ease-in-out delay-150 bg-Slate-50 hover:-translate-y-1 hover:scale-110 hover:bg-Slate-100 duration-300'>
            <FaBookReader className="mx-auto" size={40} />
            <h3 className='text-xl'>10000+</h3>
            <p>Learners</p>
          </div>
          <div className='shadow-lg p-5 text-center rounded-lg transition ease-in-out delay-150 bg-Slate-50 hover:-translate-y-1 hover:scale-110 hover:bg-Slate-100 duration-300'>
            <SiGotomeeting className="mx-auto" size={40} />
            <h3 className='text-xl'>100+</h3>
            <p>Labs</p>
          </div>
          <div className='shadow-lg p-5 text-center rounded-lg transition ease-in-out delay-150 bg-Slate-50 hover:-translate-y-1 hover:scale-110 hover:bg-Slate-100 duration-300 '>
            <SiSpeedtest className="mx-auto" size={40} />
            <h3 className='text-xl'>700+</h3>
            <p>Tests</p>
          </div>
          <div className='shadow-lg p-5 text-center rounded-lg transition ease-in-out delay-150 bg-Slate-50 hover:-translate-y-1 hover:scale-110 hover:bg-Slate-100 duration-300'>
            <MdAssignment className="mx-auto" size={40} />
            <h3 className='text-xl'>10000+</h3>
            <p>Resume</p>
          </div>
        </div>
      </div>
      {/* <Portfolio/> */}
      <Portfolionewpage/>

    </>
  );
};

export default Intro;
