import Navbar from "./components/Navbar/Navbar";
import Toolbar from "./components/Toolbar/Toolbar";
import "./App.css";
import { BrowserRouter , Route , Routes } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import { useContext } from "react";
import { themeContext } from "./Context";
import Resume from "./Page/Resume.jsx";
import Language from "./Page/Language";
import Digialsoluction from "./components/Digitalsoluction/digialsoluction.jsx";
// import Ailab from "./Page/Ailab.jsx";
import Robotics from "./Page/Robotics.jsx";
import Home from "./Page/Home";
import VirtualSchool from "./components/VirtualSchool/VirtualSchool";
import { Consultancy } from "./components/Educationalsolutions/Consultancy";
import  Erpsolution  from "./components/Educationalsolutions/Erpsolution";
import  Attendance  from "./components/Educationalsolutions/Attandance";
import  Uhf  from "./components/Educationalsolutions/Uhf";
import  {Smartschool}  from "./components/Educationalsolutions/Smartschool";
import  Firesafety  from "./components/Educationalsolutions/Firesafety";
import  Transport  from "./components/Educationalsolutions/Tasnport";
import  Stationary  from "./components/Educationalsolutions/Stationary";
import  Uniform  from "./components/Educationalsolutions/Uniform";
import  Furniture  from "./components/Educationalsolutions/Furniture";
import  {Maintenance}  from "./components/Educationalsolutions/Maintenance";
import  Cybersecurity  from "./components/Educationalsolutions/Cybersecurity";
import  {Networking}  from "./components/Educationalsolutions/Networking";
// import Contact from "./components/Contact/Contact.jsx";
import Virturalunivercity from "./components/Educationalsolutions/Virturalunivercity.jsx"
import Certification from "./components/Educationalsolutions/Certification.jsx"
// import Contact1 from "./components/contact1/contact1.jsx";
import Contact from "./components/Contact/Contact.jsx";
import Portfolio from "../src/Page/portfolio.jsx";
import Portfolionewpage from "./Page/portfolionewpage.jsx";
function App() {

  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  
  return (
    
    <div
      className="App"
      style={{
        background: darkMode ? "black" : "",
        color: darkMode ? "white" : "",
      }}
    >
    <BrowserRouter>
      <Toolbar />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/resume" element={<Resume/>}/>
        <Route path="/language" element={<Language />} />
        <Route path="/robotics" element={<Robotics />}/>
        <Route path="/digialsoluction" element={<Digialsoluction/>}/>   
        <Route path="/virtualSchool" element={<VirtualSchool/>}/>
        <Route path="/consultancy" element={<Consultancy/>}/>
        <Route path="/erpsolution" element={<Erpsolution/>}/>
        <Route path="/attendance" element={<Attendance/>}/>
        <Route path="/uhf" element={<Uhf/>}/>
        <Route path="/smartschool" element={<Smartschool/>}/>
        <Route path="/firesafety" element={<Firesafety/>}/>
        <Route path="/transport" element={<Transport/>}/>
        <Route path="/stationary" element={<Stationary/>}/>
        <Route path="/uniform" element={<Uniform/>}/>
        <Route path="/furniture" element={<Furniture/>}/>
        <Route path="/maintenance" element={<Maintenance/>}/>
        <Route path="/cybersecurity" element={<Cybersecurity/>}/>
        <Route path="/networking" element={<Networking/>}/> 
        <Route path="/virturalunivercity" element={<Virturalunivercity/>}/>
        <Route path="/certification" element={<Certification/>}/> 
        <Route path="/portfolio" element={<Portfolio/>}/> 
        <Route path="/portfolionewpage" element={<Portfolionewpage/>}/> 
        <Route path="/contact" element={<Contact/>}/> 
        </Routes>
      </BrowserRouter>
      <Footer />
    
    </div>
  );
}

export default App;
