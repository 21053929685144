import React, { useState, useEffect } from 'react';

const HeadingText = () => {
  const words = ["Welcome to LiveLake.", "Your Institution Automation Partner.", "Expert To Help You."]; // Array of words
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [currentWord, setCurrentWord] = useState(words[0]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 3000); 

    return () => clearTimeout(timer);
  }, [currentWordIndex, words.length]);

  useEffect(() => {
    if (currentWordIndex === words.length) {
      setTimeout(() => {
        setCurrentWordIndex(0);
      }, 2000); 
    } else {
      setCurrentWord(words[currentWordIndex]);
    }
  }, [currentWordIndex, words]);

  return <h1>{currentWord}</h1>;
};

export default HeadingText;