import React from 'react'
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import "./recognition.css";
import image2 from '../../img/ERP Solutions .jpg';
import image3 from '../../img/ERP Solutions Image.png';
import { Helmet } from 'react-helmet';
const images = [
  image2,
  image3,
];
const Erpsolution = () => {
  return (
    <div>
         <Helmet>
                <meta charSet="utf-8" />
                <title>Erpsolution</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
    <div className='font-semibold text-center text-3xl '>
    {/* <h1> Consultancy </h1> */}
    </div>
    <div className="box scroll-container pt-8" id='rec'>
    <Carousel useKeyboardArrows={true} autoPlay={true} interval={2000} infiniteLoop={true} showStatus={false} >
      {images.map((URL, index) => (
        <div className="slide" key={index} >
          <img alt="sample_file" src={URL} />
        </div>
      ))}
    </Carousel>
  </div>
  <div className='font-semibold text-center text-3xl pt-4'>
    <h1> ERP Solution  </h1>
    </div>
     <div className='lg:grid lg:grid-cols-2 grid pt-5'>
      
      <div className=' '>
      <div className='p-8 pl-6 justify-center text-justify w-[90%] shadow-lg shadow-gray-500/50 '>
      <b>Our service</b> provider company that caters to
schools and colleges specializes in delivering comprehensive software
solutions to educational institutions. These solutions are designed to
streamline and optimize various administrative and academic processes,
ultimately enhancing efficiency, productivity, and overall management within
educational organizations. Here&#39;s a detailed description of such a company:
Livelake Education<br></br>

<b>LiveLake Education</b> is a leading ERP service provider dedicated to
transforming the way schools and colleges operate and manage their daily
activities. We understand the unique challenges faced by educational institutions
in today&#39;s rapidly evolving digital landscape, and our mission is to empower them
with cutting-edge technology solutions.

<br></br><br></br>
<li><b>Customized ERP Software:</b> LiveLake Education offers tailored ERP
software solutions that cater specifically to the needs and requirements of
educational institutions. Our software covers a wide range of functionalities,
including student management, staff management, finance and accounting,
admissions, attendance tracking, timetable scheduling, and more.<br/></li>


<li><b>Streamlined Administrative Processes:</b> Our ERP system simplifies administrative tasks such as admissions, fee collection, payroll management, and inventory control. This reduces manual paperwork, minimizes errors, and frees up staff time for more strategic activities.<br/></li>

  <li><b>Academic Excellence:</b> We provide tools to help educators effectively manage curricula, assessments, and student progress tracking. Our ERP software enables easy sharing of resources, lesson planning, and online grading, facilitating an enriched academic experience.<br/></li>

 <li><b>Real-time Data Analysis:</b> Our ERP solution offers real-time data analytics and reporting, allowing administrators to make data-driven decisions. It helps schools and colleges identify trends, track performance, and improve overall institutional effectiveness.<br/></li>

       </div>
      </div>
      {/* <div className='w-[90%] bg-gray-100 shadow-lg shadow-gray-500/50'>
      <div className="px-6 box scroll-container p-10" id='rec'>
        <img src='/solution.jpg' alt='pic' className=' h-[110%]'/>
       </div>
       </div> */}
        <div className='pl-6 justify-center text-justify w-[90%]   shadow-lg shadow-gray-500/50 p-8'>
      <li><b>Parent and Student Portals:</b> Enhance communication between the educational institution, students, and parents with dedicated portals. They can access important information like grades, attendance records, and announcements from anywhere, promoting transparency and engagement.</li>

      <li><b>Support and Training:</b> LiveLake Education provides comprehensive training and ongoing support to ensure that educational institutions can fully leverage the capabilities of our ERP system. Our experts are available to assist with any technical issues or questions.</li>

       <li><b>Security and Data Privacy:</b> We prioritize data security and comply with industry standards to protect sensitive student and institutional information. Our robust security measures safeguard against data breaches and unauthorized access.</li>

         <li><b>Scalability:</b> Our ERP solutions are scalable, making them suitable for small schools, large universities, and everything in between. Whether an institution is growing or downsizing, our software can adapt to its changing needs.</li>

          <li><b>Cost-Efficiency:</b> [Your Company Name] understands the budget constraints of educational institutions. We offer flexible pricing models that ensure affordability without compromising on the quality of our services.</li>
<br/>
  <b>Benefits:</b>
<br/>
  <b>Efficiency:</b>Eliminates redundancy and automates time-consuming tasks, allowing staff to focus on core activities.<br/>
 <b>Transparency:</b> Provides stakeholders with easy access to information, fostering collaboration and accountability.<br/>
   <b>Improved Decision-Making:</b> Access to real-time data and analytics helps administrators make informed choices.<br/>
   <b>Enhanced Communication:</b> Streamlines communication between educators, students, parents, and administrators.<br/>
   <b>Scalability:</b> Grows with the institution, adapting to changing needs.<br/>
    <b>Security:</b> Ensures the safety and confidentiality of sensitive data.<br/>

    {/* we believe that technology should enhance the educational experience, and our ERP solutions are designed to do just that. We are committed to partnering with schools and colleges to empower them in their mission to provide high-quality education while efficiently managing their operations. Contact us today to learn how our ERP services can transform your educational institution. */}


        </div>
       </div>

       <div className=''>
      
       </div>
  </div>
  )
}

export default Erpsolution