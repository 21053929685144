import React from 'react'
import { Helmet } from 'react-helmet'

const Robotics = () => {
  return (
    <div className=' text-center'>
      <Helmet>
        <title>Language | Livelake Education</title>
      </Helmet>
    <h1> Coming Soon ...</h1>
    </div>
  )
}

export default Robotics