import React from 'react'
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import "./recognition.css";
import image2 from '../../img/Stationery1.jpg';
import image3 from '../../img/STATIONARY SOLUTIONS 2.png';
import  image from '../../img/STATIONARY SOLUTIONS.png';
import { Helmet } from 'react-helmet';
const images = [
  image2,
  image3,
  image
];
const Stationary = () => {
  return (
    <div>
         <Helmet>
                <meta charSet="utf-8" />
                <title>Stationary</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
    <div className='font-semibold text-center text-3xl '>
    {/* <h1> Consultancy </h1> */}
    </div>
    <div className="box scroll-container pt-8" id='rec'>
    <Carousel useKeyboardArrows={true} autoPlay={true} interval={2000} infiniteLoop={true} showStatus={false} >
      {images.map((URL, index) => (
        <div className="slide" key={index} >
          <img alt="sample_file" src={URL} />
        </div>
      ))}
    </Carousel>
  </div>
  <div className='font-semibold text-center text-3xl pt-4'>
    <h1> Stationary Solutions </h1>
    </div>
     <div className='lg:grid lg:grid-cols-2 grid pt-5'>
      
      <div className=' '>
      <div className='pl-6 justify-center text-justify w-[90%] p-8 shadow-lg shadow-gray-500/50 '>
      <b>Our School Stationery</b> Services is your dedicated partner in providing top-quality
school supplies for students, teachers, and educational institutions. With a
mission to support learning and creativity, we offer an extensive range of
stationary products designed to equip schools and students for success.<br/><br/>

Our comprehensive catalog features everything from notebooks, pencils, and art
supplies to backpacks, rulers, and classroom organization tools. We understand
the unique needs of both primary and secondary education, which is why we
tailor our offerings to cater to students of all ages.<br/><br/>

Our quality is paramount. We source our products from trusted manufacturers
known for their durability and reliability. From eco-friendly options to fun and
engaging designs, our stationary items are carefully curated to meet the diverse
requirements of modern classrooms.
   
    </div>
      </div>
      <div className='pl-6 justify-center text-justify w-[90%] p-8 shadow-lg shadow-gray-500/50 '>
      Our commitment to convenience means that schools can count on us for bulk
orders, ensuring a steady supply of essential stationary throughout the academic
year. Our online platform simplifies the ordering process, making it easy for
educators and administrators to access the supplies they need when they need
them.<br/><br/>
<b>LiveLake Education</b> School Stationery Services values education and understands
that having the right tools can enhance the learning experience. We strive to
empower students and educators alike by providing top-notch stationary
solutions, contributing to a successful and organized educational journey.<br/><br/>

Choose <b>LiveLake Education</b>  as your trusted partner for all your school stationary
needs, and let us help you create an environment where students can excel,
teachers can teach, and learning can flourish.
   
    </div>
      {/* <div className='w-[90%] bg-gray-100 shadow-lg shadow-gray-500/50'>
      <div className="px-6 box scroll-container p-10" id='rec'>
        <img src='/solution.jpg' alt='pic' className=' h-[110%]'/>
       </div>
       </div> */}
       </div>




  </div>
  )
}

export default Stationary