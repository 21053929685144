import React from 'react'
import "./toolbar.css"

const Toolbar = () => {
  return (
    <div className=' p-2 text-center bg-current fixed top-0 w-full z-20'>
        <h2 className='text-white'id ="tool-head">Get Your School Automation software in just few clicks and go live in 15mins .<a href="/contact"> Click Here</a></h2>
    </div>
  )
}

export default Toolbar
