import React from 'react'
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import "./recognition.css";
import image3 from '../../img/RFID ATTENDENCE2.jpg';
import image2 from '../../img/RFID ATTENDENCE.png';
import image1  from "../../img/RFID ATENDENCE.png";
import { Helmet } from 'react-helmet';
const images = [
  image3,
  image2,
  image1,
];

const Attandance = () => {
  return (
    <div>
         <Helmet>
                <meta charSet="utf-8" />
                <title>Attendance</title>
                <link rel="canonical" href="http://mysite.com/example" />
         </Helmet>
      <div className='font-semibold text-center text-3xl '>
      {/* <h1> Consultancy </h1> */}
      </div>
      <div className="box scroll-container" id='rec'>
      <Carousel useKeyboardArrows={true} autoPlay={true} interval={2000} infiniteLoop={true} showStatus={false} >
        {images.map((URL, index) => (
          <div className="slide" key={index} >
            <img alt="sample_file" src={URL} />
          </div>
        ))}
      </Carousel>
    </div>
    <div className='font-semibold text-center text-3xl pt-4'>
      <h1> Attendance </h1>
      </div>
       <div className='lg:grid lg:grid-cols-2 grid pt-5'>
        
        
        <div className='pl-6 justify-center text-justify zz shadow-lg shadow-gray-500/50 p-8 '>
      
<b>LiveLake Eduction</b> is your trusted partner in delivering cutting-edge RFID-based attendance machine services to schools and colleges. Our mission is to revolutionize attendance management, providing educational institutions with an efficient, accurate, and hassle-free solution.<br/>

<li><b>RFID Precision:</b> Our attendance machines utilize Radio-Frequency Identification (RFID) technology, ensuring unmatched accuracy and efficiency in capturing attendance data. This eliminates the need for manual processes and helps schools and colleges maintain precise records effortlessly.</li>

<li><b>Seamless Integration:</b> Lakelake Education seamlessly integrates our RFID-based attendance solutions into your existing systems, making the transition smooth and straightforward. Whether you use traditional attendance registers or digital platforms, we ensure a hassle-free setup.<br/></li>

<li><b>Real-time Tracking:</b> Stay in control with real-time attendance tracking. Our systems enable educational institutions to monitor attendance as it happens, allowing for immediate response to any discrepancies or late arrivals.<br/></li>

       </div>
        
        {/* <div className='w-[90%] bg-gray-100 shadow-lg shadow-gray-500/50'>
        <div className="px-6 box scroll-container p-10" id='rec'>
          <img src='/' alt='pic' className=''/>
         </div>
         </div> */}

<div className='pl-6 justify-center text-justify w-[90%] shadow-lg shadow-gray-500/50 p-8'>
      
<li><b>Robust Reporting:</b> Generate comprehensive attendance reports effortlessly. [Company Name] provides customizable reporting tools that offer insights into attendance patterns, helping educational institutions make data-driven decisions.</li>

<li><b>Security and Compliance:</b> We prioritize data security and compliance with privacy regulations. Our attendance systems are designed to protect sensitive attendance data while ensuring adherence to all relevant laws.</li>

<li><b>Customized Solutions:</b> Every educational institution is unique. [Company Name]  tailors our UHF-based attendance services to meet the specific needs and scale of your school or college, whether you're a small primary school or a sprawling university campus.</li>

<li>At<b> LiveLake Education</b> , we believe that precise attendance management is fundamental to the success of educational institutions. Our UHF-based attendance machine services empower schools and colleges to automate attendance tracking, reduce administrative burden, and enhance overall operational efficiency. Join us in elevating attendance management with [Company Name] Attendance Solutions.</li>


             </div>




         </div>




    </div>
  )
}

export default Attandance