import React from 'react'
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import "./recognition.css";
// import image2 from '../../img/Consultancy image.png';
import image3 from '../../img/consultancy 2.jpg';
import { Helmet } from 'react-helmet';
const images = [
  // image2,
  image3,
];
export const Consultancy = () => {
  return (
    <>
    <div>
    <Helmet>
                <meta charSet="utf-8" />
                <title>Consultancy</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
      <div className='font-semibold text-center text-3xl '>
      {/* <h1> Consultancy </h1> */}
      </div>
      <div className="box scroll-container pt-8" id='rec'>
      <Carousel useKeyboardArrows={true} autoPlay={true} interval={2000} infiniteLoop={true} showStatus={false} >
        {images.map((URL, index) => (
          <div className="slide" key={index} >
            <img alt="sample_file" src={URL} />
          </div>
        ))}
      </Carousel>
    </div>
    <div className='font-semibold text-center text-3xl pt-4'>
      <h1> Consultancy </h1>
      </div>
       <div className='lg:grid lg:grid-cols-2 grid pt-5'>
        
       
        <div className='pl-6 justify-center text-justify w-[90%] shadow-lg shadow-gray-500/50 p-8 '>
       <b>Our</b> consulting service provider company that specializes in serving schools and colleges plays a pivotal role in helping educational institutions improve their operations, enhance educational outcomes, and navigate the complex challenges of the education sector. Here's a detailed description of such a company.<br/>

      <b> Our</b>  is a distinguished consulting service provider that partners with educational institutions, including schools, colleges, and universities, to drive positive change and foster excellence in the field of education. With a team of experienced consultants who possess deep industry knowledge and a passion for education, we are committed to empowering educational institutions to achieve their goals and deliver exceptional educational experiences.<br/>
       <b>**Key Services and Expertise:**<br/></b>

       <li><b>Strategic Planning:</b> We collaborates with educational leaders to develop and implement strategic plans that align with their institution's mission and vision. Our consultants assist in setting clear objectives, defining key performance indicators, and creating roadmaps for success.</li>
         
        <li><b>Institutional Assessment:</b> We conduct comprehensive assessments of educational institutions, evaluating their strengths, weaknesses, opportunities, and threats (SWOT analysis). This analysis forms the basis for informed decision-making and targeted improvements.</li>

       <li><b>Curriculum Development:</b> Our experts assist in designing and refining curricula to meet evolving educational standards and industry demands. We help institutions create engaging and effective learning experiences for students.</li>

           <li><b>Faculty Development:</b> LiveLake 
           Education offers faculty training and development programs to enhance teaching methodologies, promote innovative pedagogy, and improve the overall quality of instruction.</li>

        <li><b>Student Engagement and Retention:</b> We work closely with institutions to develop strategies for student engagement, retention, and success. This includes implementing support services, advising programs, and retention initiatives.</li>

         <li><b>Financial Management:</b> Our consultants provide guidance on financial planning, budgeting, and resource allocation to ensure financial sustainability while maintaining educational quality.</li>

            <li><b>Accreditation and Compliance:</b> LiveLake Education assists institutions in navigating the accreditation process and staying compliant with educational regulations and standards. We help institutions prepare for accreditation visits and audits.</li>

           </div>
           <div className='pl-6 justify-center text-justify w-[90%] shadow-lg shadow-gray-500/50 p-8 '>
           <li><b>Technology Integration:</b> We advise on the effective integration of technology into the educational process, helping institutions leverage digital tools for improved learning outcomes and administrative efficiency.</li>

         <li><b>Market Research and Enrollment Management:</b>Our market research services help institutions identify growth opportunities, target demographics, and develop enrollment strategies to attract and retain students.</li>

       <b>Benefits:</b><br/>

    <li><b>Enhanced Educational Quality:</b> Our services aim to improve the overall quality of education offered by institutions, resulting in better learning outcomes for students.</li>
      <li><b>Strategic Growth:</b> We assist institutions in developing growth strategies, expanding programs, and reaching new markets to increase their impact and revenue.</li>
        <li><b>Compliance and Accreditation:</b> Ensure that institutions meet regulatory requirements and achieve accreditation, enhancing their reputation and credibility.</li>
            <li><b>Financial Sustainability:</b> Our financial expertise helps institutions manage resources efficiently and make informed financial decisions.</li>
          <li><b>Innovation:</b> Promote innovation in teaching and learning, fostering a culture of continuous improvement.</li>
          <li><b>Student Success:</b> Our initiatives focus on improving student engagement, retention, and success, creating a positive educational experience.</li>

          we believe in the transformative power of education and the importance of supporting educational institutions in their mission to provide high-quality learning experiences. Our consulting services are tailored to each institution's unique needs and aspirations, and we are dedicated to helping them thrive in an ever-evolving educational landscape. Contact us today to explore how our consulting services can help your school or college achieve its educational goals.<br/>
          A cyber security provider company is a specialized organization that offers a range of services and solutions designed to protect businesses, institutions, and individuals from cyber threats and vulnerabilities. These companies play a critical role in safeguarding sensitive data, digital assets, and online operations.

           </div>
        {/* <div className='w-[98%] bg-gray-100 shadow-lg shadow-gray-500/50'>
        <div className="p-6 box scroll-container " id='rec'>
          <img src='/consultancy1.jpg' alt='pic' className=' h-[110%]'/>
         </div>
         </div> */}
         </div>




    </div>
    </>
  )
}
