import React from 'react'
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import "./recognition.css";
// import image2 from '../../img/fire and safety solutions.png';
import image3 from '../../img/fire and safety solutions1.jpg';
import image from '../../img/fire and safety solutions2.jpg';
import { Helmet } from 'react-helmet';
const images = [
  image,
  // image2,
  image3,
];
const Firesafety = () => {
  return (
    <div>
         <Helmet>
                <meta charSet="utf-8" />
                <title>Firesafety</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
    <div className='font-semibold text-center text-3xl '>
    {/* <h1> Consultancy </h1> */}
    </div>
    <div className="box scroll-container pt-8" id='rec'>
    <Carousel useKeyboardArrows={true} autoPlay={true} interval={2000} infiniteLoop={true} showStatus={false} >
      {images.map((URL, index) => (
        <div className="slide" key={index} >
          <img alt="sample_file" src={URL} />
        </div>
      ))}
    </Carousel>
  </div>
  <div className='font-semibold text-center text-3xl pt-4'>
    <h1> Fire And Safety </h1>
    </div>
     <div className='lg:grid lg:grid-cols-2 grid pt-5'>
      
      
      <div className='pl-6 justify-center text-justify w-[90%] shadow-lg shadow-gray-500/50 p-8 '>
      Our Company is a trusted fire safety provider company committed to safeguarding lives and properties from the devastating effects of fires. With a team of experienced professionals and a dedication to excellence, we offer a comprehensive suite of fire safety products and services designed to meet the unique needs of our clients.<br/>

       <b>Key Services and Products:</b><br/>

       <li><b>Fire Detection and Alarm Systems:</b> We provide state-of-the-art fire detection systems that use advanced sensors and technology to detect smoke, heat, or flames. Our alarm systems are designed to trigger immediate alerts and evacuation procedures when a fire is detected.</li>

        <li><b>Fire Extinguishers:</b> We offer a variety of portable fire extinguishers suitable for different types of fires, including those caused by flammable liquids, electrical equipment, and ordinary combustibles. We also provide training on how to use them effectively.</li>

       <li><b>Fire Suppression Systems:</b> For commercial and industrial clients, we install and maintain fire suppression systems that automatically release fire-extinguishing agents, such as foam or gas, to suppress fires quickly and effectively.</li>

        <li><b>Fire Safety Training:</b> We conducts fire safety training programs for individuals and organizations, educating them on fire prevention, evacuation procedures, and the proper use of fire safety equipment.</li>

       <li><b>Emergency Evacuation Plans:</b> We develop customized emergency evacuation plans for businesses, schools, and other institutions, ensuring that occupants can safely and efficiently exit a building during a fire or other emergency.</li>

     
      </div>
      
      <div className='pl-6 justify-center text-justify w-[90%] shadow-lg shadow-gray-500/50 p-8 '>
      <li><b>Fire Risk Assessments:</b> Our experts conduct comprehensive fire risk assessments to identify potential fire hazards and recommend mitigation strategies to minimize risks.</li>

        <li><b>Maintenance and Inspections:</b> We offer regular maintenance and inspections of fire safety equipment to ensure that they are in optimal working condition and compliant with safety regulations.,/</li>

       <li><b>Fire Safety Consultation:</b> LiveLake Education provides consultancy services to help businesses and institutions develop effective fire safety policies and procedures tailored to their specific needs.</li>
      <b>Benefits:</b>

        <li><b>Life Safety:</b> Protect lives by providing early fire detection and efficient evacuation procedures.</li>
  
        <li><b>Property Protection:</b> Minimize property damage by detecting and suppressing fires in their early stages.</li>
  
          <li><b>Compliance:</b> Ensure compliance with local fire safety codes and regulations.</li>
  
           <li><b>Peace of Mind:</b> Clients can have confidence in their ability to respond to fire emergencies effectively.</li>
  
          <li><b>Reduced Liability:</b> Implementing robust fire safety measures can reduce liability and insurance costs.</li><br/>

            we understand the critical importance of fire safety and the profound impact it can have on people's lives and businesses. Our commitment to excellence, combined with a comprehensive range of fire safety products and services, ensures that our clients are well-prepared to prevent, detect, and respond to fires. Contact us today to enhance your fire safety measures and protect what matters most.

      </div>
      
      {/* <div className='w-[90%] bg-gray-100 shadow-lg shadow-gray-500/50'>
      <div className="px-6 box scroll-container p-10" id='rec'>
        <img src='/solution.jpg' alt='pic' className=' h-[110%]'/>
       </div>
       </div> */}
       </div>

  </div>
  )
}

export default Firesafety