import React from 'react';
import { Link } from 'react-router-dom';
// import { VirtualSchool } from 'swiper';
// import { TERipple } from 'tw-elements-react';

export default function RemoteProctoring() {


  return (
     <div>
        <div className=' font-semibold text-center text-3xl py-7'>
           <h1> End To End Solution For Educational Institutions</h1>
          </div>  
        <div className=' justify-center sm:pl-7 sm:pr-5'>
            
        {/* <div className='flex gap-4 pt-10'> */}

         <div class="flex justify-center gap-3 flex-wrap pb-4">

<div class="md:max-w-[17rem] hover:scale-110 transition duration-300">
    <div class="md:max-w-[17rem] bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <img class="rounded-t-lg mx-auto max-h-20 max-w-3xl" src="CONSULTANCY.png" alt="pic" />
        <div class="p-4">
            <h5 class="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">Consultancy</h5>
            <p class="mb-3 text-gray-700 dark:text-gray-400 text-sm">At LiveLake Education Consultancy, we specialize in providing expert guidance and innovative solutions to schools and educational institutions worldwide. With a deep understanding of the complexities.</p>
            <Link to="/consultancy" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                Read more
                <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                </svg>
            </Link>
        </div>
    </div>
</div>

<div class="md:max-w-[17rem] hover:scale-110 transition duration-300">
    <div class="md:max-w-[17rem] bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <img class="rounded-t-lg mx-auto max-h-20 max-w-3xl" src="ERP SYSTEM.png" alt="pic" />
        <div class="p-4">
            <h5 class="mb-2 text-xl md:text-xl font-bold tracking-tight text-gray-900 dark:text-white">ERP Solutions</h5>
            <p class="mb-3 text-gray-700 dark:text-gray-400 text-sm">Our comprehensive ERP solutions encompass finance, inventory, HR, and CRM modules, tailored to streamline operations, enhance data visibility, and drive efficiency for businesses of all sizes.</p>
            <Link to="/Erpsolution" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                Read more
                <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                </svg>
            </Link>
        </div>
    </div>
</div>

<div class="md:max-w-[17rem] hover:scale-110 transition duration-300">
    <div class="md:max-w-[17rem] bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <img class="rounded-t-lg mx-auto max-h-20 max-w-3xl py-2" src="RFIDATTENDANCE.png" alt="pic" />
        <div class="p-4">
            <h5 class="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">RFID Attendance</h5>
            <p class="mb-3 text-gray-700 dark:text-gray-400 text-sm">Elevate attendance management with our RFID solutions. Our technology streamlines tracking, enhances security, and ensures accurate attendance records for schools, businesses.</p>
            <Link to="/attendance" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                Read more
                <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                </svg>
            </Link>
        </div>
    </div>
</div>

<div class="md:max-w-[17rem] hover:scale-110 transition duration-300">
    <div class="md:max-w-[17rem] bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <img class="rounded-t-lg mx-auto max-h-20 max-w-3xl py-2" src="UHF BASED ATTENDENCE.png" alt="pic" />
        <div class="p-4">
            <h5 class="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">UHF Based Attendance</h5>
            <p class="mb-3 text-gray-700 dark:text-gray-400 text-sm">Implementing UHF-based attendance tracking systems for schools and colleges, enabling efficient and secure student and staff attendance monitoring with real-time data and reporting capabilities.</p>
            <Link to="/Uhf" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                Read more
                <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                </svg>
            </Link>
        </div>
    </div>
</div>

<div class="md:max-w-[17rem] hover:scale-110 transition duration-300">
    <div class="md:max-w-[17rem] bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <img class="rounded-t-lg mx-auto max-h-20 max-w-3xl py-2" src="SMART SCHOOL.png" alt="pic" />
        <div class="p-4">
            <h5 class="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">Smart School Solutions</h5>
            <p class="mb-3 text-gray-700 dark:text-gray-400 text-sm">Empower schools with our comprehensive smart school solution. We offer innovative technologies and services to enhance teaching, streamline administration, and elevate the overall educational experience.</p>
            <Link to="/Smartschool" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                Read more
                <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                </svg>
            </Link>
        </div>
    </div>
</div>

<div class="md:max-w-[17rem] hover:scale-110 transition duration-300">
    <div class="md:max-w-[17rem] bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <img class="rounded-t-lg mx-auto max-h-20 max-w-3xl py-2" src="FIRE AND SAFETY.png" alt="pic" />
        <div class="p-4">
            <h5 class="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">Fire And Safety Solutions</h5>
            <p class="mb-3 text-gray-700 dark:text-gray-400 text-sm">Enhance safety with our fire and safety solutions. We offer expert services and technology to protect lives and property, ensuring peace of mind for businesses and individuals.</p>
            <Link to="/Firesafety" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                Read more
                <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                </svg>
            </Link>
        </div>
    </div>
</div>

<div class="md:max-w-[17rem] hover:scale-110 transition duration-300">
    <div class="md:max-w-[17rem] bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <img class="rounded-t-lg mx-auto max-h-20 max-w-3xl py-2" src="SMART SCHOOL TRANSPORT.png" alt="pic" />
        <div class="p-4">
            <h5 class="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">Smart Transport Solutions</h5>
            <p class="mb-3 text-gray-700 dark:text-gray-400 text-sm">Transform transportation with our smart transport solutions. We provide innovative technologies and services to optimize routes, enhance safety, and improve overall transport efficiency.</p>
            <Link to="/Smartschool" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                Read more
                <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                </svg>
            </Link>
        </div>
    </div>
</div>

<div class="md:max-w-[17rem] hover:scale-110 transition duration-300">
    <div class="md:max-w-[17rem] bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <img class="rounded-t-lg mx-auto max-h-20 max-w-3xl py-2" src="STATIONARY.png" alt="pic" />
        <div class="p-4">
            <h5 class="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">Stationary Solutions</h5>
            <p class="mb-3 text-gray-700 dark:text-gray-400 text-sm">Streamline your stationary needs with our comprehensive stationary solutions. We offer a wide range of high-quality office and school supplies to meet your requirements efficiently.</p>
            <Link to="/Stationary" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                Read more
                <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                </svg>
            </Link>
        </div>
    </div>
</div>

<div class="md:max-w-[17rem] hover:scale-110 transition duration-300">
    <div class="md:max-w-[17rem] bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <img class="rounded-t-lg mx-auto max-h-20 max-w-3xl py-2" src="SCHOOL UNIFORM.png" alt="pic" />
        <div class="p-4">
            <h5 class="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">Uniform And Accessories</h5>
            <p class="mb-3 text-gray-700 dark:text-gray-400 text-sm">We offer a wide range of uniforms and accessories, including shirts, pants, skirts, ties, belts, shoes, and more, ensuring a complete and professional appearance for any organization.</p>
            <Link to="/Uniform" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                Read more
                <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                </svg>
            </Link>
        </div>
    </div>
</div>

<div class="md:max-w-[17rem] hover:scale-110 transition duration-300">
    <div class="md:max-w-[17rem] bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <img class="rounded-t-lg mx-auto max-h-20 max-w-3xl py-2" src="FURNITURE.png" alt="pic" />
        <div class="p-4">
            <h5 class="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">Furniture And Other</h5>
            <p class="mb-3 text-gray-700 dark:text-gray-400 text-sm">We offer a wide range of furniture and other essential items for schools and colleges, ensuring functional, comfortable, and aesthetically pleasing learning environments.</p>
            <Link to="/Furniture" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                Read more
                <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                </svg>
            </Link>
        </div>
    </div>
</div>

<div class="md:max-w-[17rem] hover:scale-110 transition duration-300">
    <div class="md:max-w-[17rem] bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <img class="rounded-t-lg mx-auto max-h-20 max-w-3xl py-2" src="SCHOOL MAINTANANCE.png" alt="pic" />
        <div class="p-4">
            <h5 class="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">Schools/College Maintenance</h5>
            <p class="mb-3 text-gray-700 dark:text-gray-400 text-sm">Comprehensive school and college maintenance services encompass repairs, cleaning, landscaping, and facility management for optimal functionality and aesthetic appeal.</p>
            <Link to="/Maintenance" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                Read more
                <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                </svg>
            </Link>
        </div>
    </div>
</div>

<div class="md:max-w-[17rem] hover:scale-110 transition duration-300">
    <div class="md:max-w-[17rem] bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <img class="rounded-t-lg mx-auto max-h-20 max-w-3xl py-2" src="CYBER SECURITY.png" alt="pic" />
        <div class="p-4">
            <h5 class="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">Cyber Security Solutions</h5>
            <p class="mb-3 text-gray-700 dark:text-gray-400 text-sm">Protect your digital assets with our comprehensive cybersecurity solutions. We provide expert services and technologies to safeguard your data and systems from cyber threats.</p>
            <Link to="/Cybersecurity" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                Read more
                <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                </svg>
            </Link>
        </div>
    </div>
</div>

<div class="md:max-w-[17rem] hover:scale-110 transition duration-300">
    <div class="md:max-w-[17rem] bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <img class="rounded-t-lg mx-auto max-h-20 max-w-3xl py-2" src="NETWORKINGHARDWARE.png" alt="pic" />
        <div class="p-4">
            <h5 class="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">Networking And Hardware</h5>
            <p class="mb-3 text-gray-700 dark:text-gray-400 text-sm">Protect your digital assets with our advanced cybersecurity solutions. Our expert services and cutting-edge technologies ensure the security of your data and systems, providing comprehensive protection for your business.</p>
            <Link to="/Networking" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                Read more
                <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                </svg>
            </Link>
        </div>
    </div>
</div>

<div class="md:max-w-[17rem] hover:scale-110 transition duration-300">
    <div class="md:max-w-[17rem] bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <img class="rounded-t-lg mx-auto max-h-20 max-w-3xl py-2" src="Virtual.png" alt="pic" />
        <div class="p-4">
            <h5 class="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">Virtual University And School
</h5>
            <p class="mb-3 text-gray-700 dark:text-gray-400 text-sm">Optimize your network with our reliable networking hardware solutions. We offer a range of hardware and equipment to enhance connectivity and communication in your business or organization.</p>
            <Link to="/Virturalunivercity" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                Read more
                <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                </svg>
            </Link>
        </div>
    </div>
</div>

<div class="md:max-w-[17rem] hover:scale-110 transition duration-300">
    <div class="md:max-w-[17rem] bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <img class="rounded-t-lg mx-auto max-h-20 max-w-3xl py-2" src="Interactive.png" alt="pic" />
        <div class="p-4">
            <h5 class="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">Interactive Online Courses with Certification </h5>
            <p class="mb-3 text-gray-700 dark:text-gray-400 text-sm">Offering interactive online courses with certification, we provide engaging, flexible, and accessible learning opportunities across various subjects and industries to enhance skills and career.</p>
            <Link to="/Certification" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                Read more
                <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                </svg>
            </Link>
        </div>
    </div>
</div>

         </div>


</div>
</div>






  );
}