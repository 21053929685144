import React from 'react'
import { Helmet } from 'react-helmet'

const Language = () => {
  return (
    <h1 className=' text-center'>
      <Helmet>
        <title>Language | Livelake Education</title>
      </Helmet>
      <h1> Coming Soon ...</h1>
    </h1>
  )
}

export default Language