import React, { useState } from "react";
import {RiCloseLine , RiMenu3Line} from 'react-icons/ri';
import Appbar from "../Appbar/Appbar";
import "./Navbar.css";
import logo from "../../img/1.png";
import { Link } from "react-scroll";
// import { Helmet } from "react-helmet";

const Menu = ()=>{
  <>
    <ul style={{ listStyleType: "none" }}>
    <li><Link to="about" spy={true} smooth={true}>Remote Proctoring</Link></li>
    <li><Link to="rec" spy={true} smooth={true}>Recoginition</Link></li>
    <li><Link to="customers" spy={true} smooth={true}>Customers</Link></li>
    <li><Link to="testimonial" spy={true} smooth={true}>Help & Support</Link></li>
    <li><Link to="contact" spy={true} smooth={true}><button className="button n-button">Contact</button></Link></li>

    </ul>
  </>
}
const Navbar = () => {
  const [toogle , setToogle] = useState(false)
  return (
    <div className="n-wrapper w-full pl-3 pt-8 pr-8 pb-8 mt-10" id="Navbar">
      <div className="n-left">
        <div className="n-name pt-8 w-44 ">
          <img src={logo} alt="" />
        </div>
       
      </div>
      
      <div className="n-right">
        <div className="n-list">
        
          <Appbar />
        </div>
        <div className="pt-8">
          {/* 
         <a href="/contact">
          <button className="button n-button">Contact</button>
         </a> */}

        </div>

        <div className="navbar-menu">
        { toogle ? <RiCloseLine color="#00000" size={27} onClick={() =>setToogle(false)} />
                :   <RiMenu3Line color="#00000" size={27} onClick={() =>setToogle(true)} />

        }
        { toogle && (
          <div className="navbar-menu-container ">
            <div className="navbar-menu-container-links">
              <Menu />
            </div>
          </div>
        )}

        </div>
        
      </div>


      
    </div>
    
  );
};

export default Navbar;
