import React from 'react'
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import "./recognition.css";
import image2 from '../../img/NETWORKING HARDWARE SOLUTIONS.png';
import image3 from '../../img/NETWORKING HARDWARE SOLUTIONS1.png';
import { Helmet } from 'react-helmet';
const images = [
  image3,
  image2,

];
export const Networking = () => {
  return (
    <div>
         <Helmet>
                <meta charSet="utf-8" />
                <title>Networking</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
    <div className='font-semibold text-center text-3xl '>
    {/* <h1> Consultancy </h1> */}
    </div>
    <div className="box scroll-container pt-8" id='rec'>
    <Carousel useKeyboardArrows={true} autoPlay={true} interval={2000} infiniteLoop={true} showStatus={false} >
      {images.map((URL, index) => (
        <div className="slide" key={index} >
          <img alt="sample_file" src={URL} />
        </div>
      ))}
    </Carousel>
  </div>
  <div className='font-semibold text-center text-3xl pt-4'>
    <h1> Networking Hardware  </h1>
    </div>
     <div className='lg:grid lg:grid-cols-2 grid pt-5'>
      
    
      <div className='pl-6 justify-center text-justify w-[90%] shadow-lg shadow-gray-500/50 p-8'>
      <b>Livelake Education</b> is your dedicated partner in providing cutting-edge
networking hardware solutions to schools and colleges. With a deep
understanding of the critical role connectivity plays in modern education, we
offer a comprehensive suite of services designed to empower educational
institutions with reliable, secure, and high-performance networking
infrastructure.<br/>

<li><b>Robust Network Solutions:</b> We specialize in designing, implementing, and
maintaining robust network solutions tailored to the specific needs of
educational environments. From wired and wireless networks to data centers
and cloud connectivity, LiveLake Education  ensures seamless and uninterrupted
connectivity.</li>
<li><b>Security and Compliance:</b> We prioritize the security and compliance needs of
educational institutions. Our network solutions are equipped with state-of-the-
art security measures, ensuring data protection, content filtering, and
compliance with industry standards and regulations.</li>

<li><b>Scalability:</b> Educational institutions vary in size and complexity LiveLake Education 
provides scalable solutions that can grow with your institution. Whether you&#39;re a
small school or a large university, we have the expertise to meet your networking
requirements.</li>

    
     </div>
     <div className='pl-6 justify-center text-justify w-[90%] shadow-lg shadow-gray-500/50 p-8'>
     <li><b>24/7 Support:</b> Connectivity is mission-critical for schools and colleges. Our team
of experienced technicians is available 24/7 to provide support, address issues,
and ensure network uptime, allowing educators to focus on teaching and
learning.</li>

     <li><b>Cost-Efficiency:</b> We understand the budget constraints that educational
institutions often face. LiveLake Solution offers cost-effective solutions that
maximize the efficiency of your networking infrastructure while minimizing
operational expenses.</li>
<li>
<b>Education-Centric Approach:</b> We recognize that the educational landscape is
unique.LiveLake Education collaborates closely with schools and colleges to align
our solutions with their specific goals and objectives, enhancing the overall
learning experience.</li>

At <b>LiveLake Education</b> , we believe that a strong and reliable network
infrastructure is the backbone of educational excellence in the digital age. We
are committed to providing networking solutions that empower educators and
students with the connectivity they need to thrive. Join us in unlocking the
potential of your school or college with LiveLake Education.

    </div>

      {/* <div className='w-[90%] bg-gray-100 shadow-lg shadow-gray-500/50'>
      <div className="px-6 box scroll-container p-10" id='rec'>
        <img src='/solution.jpg' alt='pic' className=' h-[110%]'/>
       </div>
       </div> */}

       </div>




  </div>
  )
}
