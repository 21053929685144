import React from 'react'
// import virtual from "../../img/virtual.png";
import "./VirtualSchool.css";


const VirtualSchool = () => {
    return (
        // <div>Blog</div>
        <>
       
      <div>
        <div>
            <h1 className='text-4xl font-opensans'> End to End Solution For Educational Institution</h1>
        </div>
      </div>
        
        </>
    )
}

export default VirtualSchool;